<template>
  <div class="westudy-platform">
    <h2 class="westudy-platform__title">{{ title }}</h2>

    <div class="westudy-platform__cards">
      <a
        v-for="(card, index) in cards"
        :href="card.link"
        :key="index"
        class="westudy-platform__cards-item"
      >
        <div v-if="card.image" class="westudy-platform__cards-item-image">
          <picture>
            <source :srcset="card.image.srcTabletWebp" type="image/webp" media="(max-width: 991px)">
            <source :srcset="card.image.srcTablet" type="image/jpg" media="(max-width: 991px)">
            <source :srcset="card.image.srcWebp" type="image/webp">
            <img :alt="card.image.alt" :src="card.image.src">
          </picture>
        </div>

        <template v-else>
          <h3 class="westudy-platform__cards-item-title">{{ card.title }}</h3>

          <div class="westudy-platform__cards-item-content" v-html="card.content"/>

          <VIconMTS
            name="mts/icon-mts--arrow-link"
            width="24"
            height="24"
            fill="#1d2023"
            class="westudy-platform__cards-item-icon"
          />
        </template>
      </a>
    </div>
  </div>
</template>

<script>
import WestudyPlatformMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyPlatformMixin";
import VIconMTS from "@/components/Common/MTS/VIconMTS";

export default {
  name: 'WestudyPlatformDesktop',
  components: { VIconMTS },
  mixins: [WestudyPlatformMixin],
}
</script>

<style lang="scss" scoped>
.westudy-platform {
  margin-bottom: 64px;

  @include screen-down('lg') {
    margin-bottom: 40px;
  }

  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    max-width: 475px;
    margin: 0 auto 32px;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;

    &-item {
      display: flex;
      flex-direction: column;
      background-color: $color-white;
      padding: 32px;
      border-radius: 24px;
      transition: 0.3s ease-out;
      color: inherit;
      text-decoration: none;
      overflow: hidden;

      @include screen-down('lg') {
        padding: 24px;
      }

      &:hover {
        background-color: $color-yellow;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(6) {
        padding: 0;
      }

      &-image {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-title {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 16px;
      }

      &-content {
        font-size: 20px;
        line-height: 27px;
        max-width: 480px;

        ::v-deep(ul) {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            margin-bottom: 12px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &-icon {
        margin-top: auto;
      }
    }
  }
}
</style>
